const calculateCat = ({ amount, monthlyPayment, totalPayments }) => {

  const getCATValue = (total, pay, payments, cat) => {
    const values = Array.from({ length: payments }, (_, i) => i + 1)
      .map(i => pay / Math.pow((1 + (cat / 100)), i / 12));

    return total - values.reduce((acc, val) => acc + val, 0);
  };

  const findCat = (min, max) => {
    const cat = (min + max) / 2;
    const value = getCATValue(amount, monthlyPayment, totalPayments, cat);
    if (max - min < 0.0001) {
      return cat;
    } else if (value > 0) {
      return findCat(min, cat);
    } else {
      return findCat(cat, max);
    }
  };

  return findCat(0, 100);
};

export default calculateCat;
