import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Calculator from "../../components/calculator"

import "./loan-calculator.scss"

const LoanCalculator = ({ intl, location }) => {
  const onFinish = _ => {}
  const menuSelector = location?.state?.menuSelector || 1
  return (
    <Layout prefooter="2" menuSelector={menuSelector}>
      <SEO title="LoanCalculator" />
      <div className="calculator-header">
        <div className="calculator-title">
          {intl.formatMessage({ id: "loan-calculator-title" })}
          <div />
          <div className="calculator-title-message">
            {intl.formatMessage({ id: "loan-calculator-title-loan" })}
          </div>
        </div>
      </div>
      <Calculator
        loanAmount={location.state?.loanAmount}
        onFinish={onFinish}
        prefooter="2"
      />
    </Layout>
  )
}

export default injectIntl(LoanCalculator)
