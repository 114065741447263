import "../../theme/colors.scss"
import React, { useState, useEffect } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { Form, Button, Card, Slider } from "antd"
import CurrencyInput from "../../components/currency-input"
import BTC from "../../images/btc.svg"
import ETH from "../../images/eth.svg"

import "./calculator.scss"
import calculateCat from "../../utils/calculateCat"

const Calculator = ({ intl, formProps, loanAmount }) => {
  const minLoanAmount = 1000

  const maxLoanAmount = 250000

  const [loan, setLoan] = useState()

  const [formValue, setFormValue] = useState({
    amount: loanAmount,
    time: 6,
  })

  const roundNumber = number =>
    number
      ? `${(parseFloat(number) || 0)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
      : "-"

  const toMoneyFormat = number => (number ? `$ ${roundNumber(number)}` : "-")

  useEffect(
    _ => {
      setFormValue({
        amount: loanAmount,
        time: 6,
      })
    },
    [loanAmount]
  )

  useEffect(() => {
    const amount = Number(formValue.amount)
    const time = formValue.time
    if (amount && amount >= minLoanAmount && amount <= maxLoanAmount && time) {
      const monthlyRate = 0.0175 * 1.16

      const opening = amount * 0.0175 * 1.16
      const monthlyPayment =
        ((amount + opening) * ((1 + monthlyRate) ** time * monthlyRate)) /
        ((1 + monthlyRate) ** time - 1)
      const total = monthlyPayment * time + opening;
      const cat = calculateCat({ amount, monthlyPayment, totalPayments: time });
      const collaterals = amount * 1.25;

      setLoan({
        monthlyPayment,
        amount,
        cat,
        tax: 1,
        time,
        collaterals,
        total,
        opening,
      })
    } else {
      setLoan({})
    }
  }, [formValue])

  const onChange = value => {
    setFormValue({
      ...formValue,
      ...value,
    })
  }

  const onFinish = value => {
    window.location.replace(
      `${process.env.ADMIN_URL}/registrarse/?amount=${value.amount}&months=${value.time}`
    )
  }

  return (
    <div className="calculator">
      <div className="col">
        <Form
          layout="vertical"
          name="loan"
          onValuesChange={onChange}
          onFinish={onFinish}
          initialValues={formValue}
          {...formProps}
        >
          <Form.Item
            label={
              <div className="col">
                {intl.formatMessage({ id: "loan-calculator-amount-label-1" })}
                <label className="label-calculator">
                  <h3>
                    {intl
                      .formatMessage({ id: "loan-calculator-amount-limits" })
                      .replace("minLoanAmount", toMoneyFormat(minLoanAmount))
                      .replace("maxLoanAmount", toMoneyFormat(maxLoanAmount))}
                  </h3>
                </label>
                {intl.formatMessage({ id: "loan-calculator-amount-label-2" })}
              </div>
            }
            name="amount"
            rules={[
              { required: true, message: "Por favor introduzca el monto!" },
            ]}
          >
            <CurrencyInput
              min={minLoanAmount}
              max={maxLoanAmount}
              size="large"
              placeholder={intl.formatMessage({
                id: "loan-calculator-amount-placeholder",
              })}
            />
          </Form.Item>
          <Form.Item
            label={
              <div className="col">
                {intl.formatMessage({ id: "loan-calculator-time-label" })}
              </div>
            }
            name="time"
            rules={[
              { required: true, message: "Please input your desired time!" },
            ]}
            className="slider-container"
          >
            <Slider
              step={1}
              min={3}
              max={48}
              tooltipVisible
              tooltipPlacement="bottom"
              tipFormatter={tipFormatter => (
                <div className="tooltip">{tipFormatter + " MESES"}</div>
              )}
              trackStyle={{
                backgroundColor: "var (--primary-color)",
                color: "#FF0000",
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "2em", minWidth: "255px" }}
            >
              {intl.formatMessage({ id: "loan-calculator-button" })}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="col">
        <Card bordered={false}>
          <div className="col">
            <span className="normal-text">
              {intl.formatMessage({ id: "loan-result-title" })}{" "}
              {loan?.time
                ? `${loan.time} ${intl.formatMessage({
                    id: loan.time > 1 ? "plural-month" : "singular-month",
                  })}`
                : ""}
            </span>
            <span className="big-text-bold">
              {toMoneyFormat(loan?.monthlyPayment)}
            </span>
          </div>
          <div className="row">
            <div className="col">
              <span className="normal-text">
                {intl.formatMessage({ id: "loan-result-amount" })}
              </span>
              <span className="small-text-bold">
                {toMoneyFormat(loan?.amount)}
              </span>
            </div>
            <div className="col">
              <span className="normal-text">
                {intl.formatMessage({ id: "loan-result-opening" })}
              </span>
              <span className="small-text-bold">
                {toMoneyFormat(loan?.opening)}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="normal-text">
                {intl.formatMessage({ id: "loan-result-credit-total" })}
              </span>
              <span className="small-text-bold">
                {toMoneyFormat(loan?.monthlyPayment * loan?.time)}
              </span>
            </div>
            <div className="col">
              <span className="normal-text-bold">
                {intl.formatMessage({ id: "loan-result-cat" })}
              </span>
              <span className="small-text-bold">{roundNumber(loan?.cat)}</span>
            </div>
          </div>
          <div className="row space-top">
            <div className="col">
              <span className="normal-text">
                {intl.formatMessage({ id: "loan-result-collaterals" })}
              </span>

              <span
                className="small-text-bold-accent"
                style={{ color: "$primary-color", marginBottom: "2em" }}
              >
                {loan?.collaterals
                  ? `${toMoneyFormat(loan?.collaterals)} ${intl.formatMessage({
                      id: "loan-result-currency",
                    })}`
                  : "-"}
              </span>
              <span>
                {loan?.collaterals && (
                  <div className="row coins">
                    <BTC />
                    <ETH />
                  </div>
                )}
              </span>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default injectIntl(Calculator)
